const styles = {
  container:
    'fixed left-0 right-0 top-0 flex flex-col grow items-start py-4 sm:py-8 bg-black z-20 border-b border-[rgb(31,41,55)]',
  content: 'flex flex-row items-center justify-between grow font-display',

  head: {
    container: 'flex items-center gap-x-12',

    head: '',
    foot: 'hidden sm:flex flex-row gap-x-4',
  },
  foot: {
    container: 'flex flex-row items-center gap-x-4',

    head: '',
    foot: 'w-[3.5rem]',
  },

  link: {
    base: 'hover:text-black hover:bg-offwhite-500 cursor-pointer',
  },

  primaryLink: {
    base: 'text-secondary-500 hover:text-black hover:bg-secondary-500 cursor-pointer',
  },
};

export default styles;
