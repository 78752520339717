'use client';

import Link from 'next/link';
import { useParams, usePathname } from 'next/navigation';
import React from 'react';

import { TNextClientComponent } from 'types/next';

import { useTranslation } from 'hooks/useTranslation';

import { styles } from '.';

const ClientLink: TNextClientComponent<any> = () => {
  const params = useParams();
  const { lang } = params;

  const pathname = usePathname();

  const { t } = useTranslation(lang as string);

  if (pathname.includes('estimate')) {
    return;
  }

  return (
    <Link href={`/${lang}/estimate`} className={styles.primaryLink.base}>
      {t('view.landing.label.makeAnEstimateShort')}
    </Link>
  );
};

export default ClientLink;
