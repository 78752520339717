'use client';

import Image from 'next/image';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import React from 'react';

import { toClassName } from 'helpers';

import i18n from 'config/i18n';

import { handleUseStrapiImageUrl } from 'helpers/strapi';

import { TNextClientComponent } from 'types/next';

import Container from 'components/Container';
import { Listbox, ListboxLabel, ListboxOption } from 'components/Listbox';

import ClientLink from './components/ClientLink';

import { styles } from '.';

type TProps = {
  routes: { id: string; label: string; href: string }[];
  lang: string;
  general: any;
};

const NavBar: TNextClientComponent<TProps> = (props) => {
  const { routes, lang, general } = props;

  const pathname = usePathname();
  const router = useRouter();

  return (
    <>
      <div className={styles.container}>
        <Container type="center">
          <div className={styles.content}>
            <div className={styles.head.container}>
              <div className={styles.head.head}>
                <Link href="/#hero">
                  <Image
                    src={handleUseStrapiImageUrl(general?.logo?.url ?? '')}
                    alt="logo"
                    width={60}
                    height={20}
                  />
                </Link>
              </div>
              <ul className={styles.head.foot}>
                {routes.map((route) => (
                  <li key={route.id} className={toClassName(styles.link.base)}>
                    <Link href={route.href}>{route.label}</Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.foot.container}>
              <div className={styles.foot.head}>
                <ClientLink />
              </div>
              <div className={styles.foot.foot}>
                <Listbox name="lang" defaultValue={lang}>
                  {i18n.locales
                    .map((locale) => ({
                      id: locale,
                      label: locale,
                      value: locale,
                    }))
                    .map((category) => (
                      <div
                        key={category.id}
                        // href={pathname.replace(lang as string, category.value)}
                        aria-label={category.label}
                        onClick={() => {
                          router.push(
                            `${pathname.replace(`${lang}`, `${category.id}`)}`,
                          );
                        }}
                      >
                        <ListboxOption value={category.value}>
                          <ListboxLabel>{category.label}</ListboxLabel>
                        </ListboxOption>
                      </div>
                    ))}
                </Listbox>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {general?.banner?.isShown && (
        <div className="fixed mt-[4.5rem] sm:mt-[6.3rem] w-full bg-primary-200 py-2 text-black text-xs z-30">
          <Container type="center">{general.banner.content}</Container>
        </div>
      )}
    </>
  );
};

export default NavBar;
