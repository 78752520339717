'use client';

import { useParams } from 'next/navigation';
import React from 'react';

import { TNextClientComponent } from 'types/next';

import InfoSection from './_components/InfoSection';

import { styles } from '.';

type TProps = {
  socials: { __typename: string; icon: string; url: string }[];
  company: any;
  generalTermsAndConditions: string;
};

const Footer: TNextClientComponent<TProps> = (props) => {
  const { socials, company, generalTermsAndConditions } = props;

  const params = useParams();

  return (
    <div className={styles.container}>
      <InfoSection
        lang={params?.lang as string}
        socials={socials}
        company={company}
        generalTermsAndConditions={generalTermsAndConditions}
      />
    </div>
  );
};

export default Footer;
