import React from 'react';

import { TNextClientComponent } from 'types/next';

import { useTranslation } from 'hooks/useTranslation';

import Container from 'components/Container';
import Icon from 'components/Icon';

import { styles } from '.';

type TProps = {
  lang: string;
  socials: { __typename: string; icon: string; url: string }[];
  company: any;
  generalTermsAndConditions: string;
};

const InfoSection: TNextClientComponent<TProps> = (props) => {
  const { lang, socials, company, generalTermsAndConditions } = props;

  const { t } = useTranslation(lang);

  return (
    <div className={styles.container} aria-disabled="true">
      <Container type="center">
        <div className="pt-12 pb-6 flex flex-col sm:flex-row gap-y-4 gap-x-8 text-xs !text-offwhite-500 !font-display text-center items-center sm:items-start sm:text-left">
          <ul className="flex flex-col sm:items-start items-center gap-y-0.5">
            <li className="font-bold">{company.name}</li>
            <li className={styles.item}>
              {company?.address?.streetName} {company?.address?.streetNumber}
            </li>
            <li className={styles.item}>
              {company?.address?.postalCode} {company?.address?.city}
            </li>
            <li className={styles.item}>{company?.address?.country}</li>
          </ul>
          <ul className="flex flex-col items-start gap-y-1">
            <li className={styles.item}>
              <Icon accessor={'phone' as any} className="text-xs" />
              {company?.telephoneNumber}
            </li>
            <li className={styles.item}>
              <Icon accessor={'envelope' as any} className="text-xs" />
              {company?.emailAddress}
            </li>
            <li className={styles.item}>
              <Icon accessor={'briefcase' as any} className="text-xs" />
              {company?.vat}
            </li>
          </ul>
        </div>
        <div className={styles.content}>
          <p
            className={styles.paragraph}
            dangerouslySetInnerHTML={{
              __html: t('label.credits')
                .replace('{date}', String(new Date().getFullYear()))
                .replaceAll(
                  '<a>',
                  `<a class="${styles.link}" href="https://www.jeromverschoote.com/" target="_blank">`,
                ),
            }}
          />

          <ul className={styles.list}>
            {generalTermsAndConditions && (
              <li>
                <a href={generalTermsAndConditions} target="_blank">
                  {t('label.terms')}
                </a>
              </li>
            )}
            {socials?.map((social) => (
              <li key={social.icon}>
                <a
                  href={social.url}
                  target="_blank"
                  className={styles.icon}
                  aria-label={social.icon}
                >
                  <Icon type="brands" accessor={social.icon as any} />
                </a>
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </div>
  );
};

export default InfoSection;
