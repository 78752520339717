const handleFixImageUrl = (url: string): string => {
  let result = url;

  // if (url.includes('/uploads/')) {
  //   const filename = url.split('/uploads/')[1];
  //   result = `${process.env.GOOGLE_STORAGE_BUCKET_URL}/${
  //     filename.split('.')[0]
  //   }/${filename}`;
  // }

  return result;
};

export const handleUseStrapiImageUrl = (url: string) => {
  let result = url;

  if (url?.startsWith('/uploads/')) {
    result = `${process.env.NEXT_PUBLIC_BACKEND_ROOT_ENDPOINT}${url}`;
  } else {
    result = handleFixImageUrl(url);
  }

  return result;
};
