const styles = {
  container: 'bg-black',
  content:
    'pb-10 flex flex-col-reverse gap-y-4 sm:flex-row justify-between items-center text-white text-xs',

  list: 'flex flex-row items-center gap-x-5',
  icon: 'text-offwhite-500 hover:opacity-40 text-base',
  paragraph:
    'max-w-xs sm:max-w-none font-display text-center text-gray-600 text-xs',

  item: 'flex flex-row items-center gap-x-1',
  link: 'hover:text-secondary-500 cursor-pointer underline',
};

export default styles;
